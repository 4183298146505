
import { defineComponent , reactive, ref, toRefs , onBeforeMount, watchEffect} from 'vue';
import { UserOutlined } from '@ant-design/icons-vue';
import { useRouter ,useRoute } from "vue-router";
import store from '@/store/index';
import { logOut } from '@/api/login/index';
import { message } from 'ant-design-vue';
import locale from 'ant-design-vue/lib/date-picker/locale/en_US';
export default defineComponent({
    name:"head-nav",
    components: {
      UserOutlined,
    },
    setup(){
      const isMenu = ref<boolean>(true)
      const router = useRouter();
      const route:any = useRoute();
      const current = ref<string[]>([]);
      const menuList = reactive({
        list:[
           {
            title:"个人",
            path:'/index/sale',
            children:[
              {title:'我的销售',key:'1',path:'/index/sale'},
              {title:'我的会员',key:'2',path:'/index/member'},
              {title:'我的体验卡',key:'3',path:'/index/experience'},
              {title:'我的实体卡',key:'4',path:'/index/entity'},
              {title:'我的个人设置',key:'5',path:'/index/settings'},
              {title:'我的提现管理',key:'6',path:'/index/withdrawManage'},
              {title:'我的二维码',key:'7',path:'/index/code'},
            ]
          },
        ],
        vipList:[
          {
            title:"团队管理",
            path:'/index/team',
            children:[
              {title:'销售团队',key:'8',path:'/index/team'},
              {title:'VIP实体卡',key:'9',path:'/index/vipEntityCard'},
              {title:'体验卡',key:'10',path:'/index/teamExperience'},
            ]
          },
          {
            title:"销售管理",
            path:'/index/saleManage',
            children:[
              {title:'大排行',key:'16',path:'/index/largeRanking'},
              {title:'利益汇总',key:'12',path:'/index/profitSummary'},
              {title:'销售汇总',key:'11',path:'/index/saleManage'},
              {title:'交易流水',key:'13',path:'/index/transactionFlow'},
              {title:'拉新排行',key:'15',path:'/index/pullRanking'},
            ]
          },
          {
            title:"个人",
            path:'/index/sale',
            children:[
              {title:'我的销售',key:'1',path:'/index/sale'},
              {title:'我的会员',key:'2',path:'/index/member'},
              {title:'我的体验卡',key:'3',path:'/index/experience'},
              {title:'我的实体卡',key:'4',path:'/index/entity'},
              {title:'我的个人设置',key:'5',path:'/index/settings'},
              {title:'我的提现管理',key:'6',path:'/index/withdrawManage'},
              {title:'我的二维码',key:'7',path:'/index/code'},
            ]
          },
          
        ]
      })
     
     const avatar = ref<string | any>('')
      const name = ref<string>('')
      watchEffect(()=>{
        // avatar.value = (store.state.userInfo as any).avatar
      })

      onBeforeMount(()=>{
       
        const active:string = route.meta.active;
        current.value = ['']
        current.value.push(active)
        let data:string | any = localStorage.getItem('userInfo')
        data = JSON.parse(data)
        
        avatar.value = localStorage.getItem('avatar');
        avatar.value == ''?avatar.value = 'https://image.zhishiquan.vip/uploads/png/20200713/708add6cba195ed0759197161bf6b897.png':avatar.value
        name.value = data.name;
        if(data.role_id <= 5){
          isMenu.value = true;
        }else{
          isMenu.value = false;
        }
      })

      const sign = ():void =>{
        logOut().then((res:any)=>{
          if(res.code == 200){
            // location.reload()
            message.success(res.message);
            store.commit('setUserInfo',{});
            router.push({name:'login'});
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            console.log(localStorage.token);
          }
        })
        
      }

      return {
        current,
        avatar,
        sign,
        isMenu,
        name,
        ...toRefs(menuList)
      }
    }
})
