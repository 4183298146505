
import headNav from '@/components/sale/head.vue';
import { LoadingOutlined } from '@ant-design/icons-vue';
import { defineComponent ,onBeforeMount,reactive,ref, toRefs, watchEffect} from 'vue';
import store from '@/store/index'
import { message } from 'ant-design-vue';
import { modifyPassword } from '@/api/personal';
import { deepClone } from '@/utils/common';
import { useRouter } from "vue-router";
// 二级路由页面
export default defineComponent({
  name: 'index',
  components:{
    headNav
  },
  setup(){
    const router = useRouter();
    const spinning = ref<boolean>(false)
    const visible = ref<boolean>(false)
    const isJump = ref<boolean>(false)
    const form = reactive({
      con_password:'',
      password:'',
    })
    watchEffect(()=>{
      // 清空加载提示
      spinning.value = store.state.spinning;
      setTimeout(()=>{
        spinning.value = false;
      },1500)
    })
    onBeforeMount(()=>{
      let info:any = localStorage.getItem('userInfo');
      info = JSON.parse(info)
      if(info.is_first_login == 0){
        visible.value = true;
      }
    })
    const handleOk = ():void=>{
      let info:any = localStorage.getItem('userInfo')
      if(!form.con_password || !form.password){
        message.error('密码不能为空')
        return
      }else if(form.con_password != form.password){
        message.error('两次输入的密码不一致')
        return
      }
      let data:any = {}
      data = deepClone(form);
      data.id = JSON.parse(info).id
      console.log(data);
      
      modifyPassword(data).then((res:any)=>{
        if(res.code == 200){
          if(res.data.is_jump == 1){
            isJump.value = true;
          }
          
          form.password = '';
          form.con_password = '';
          message.success('修改成功')
          visible.value = false;
        }
      })
    }

    const JumpLogin = ():void =>{
      localStorage.removeItem('password')
      localStorage.clear()
      router.push({name:'login'})
    }

    return {
      ...toRefs(form),
      handleOk,
      spinning,
      visible,
      isJump,
      JumpLogin
    }
  }
});
